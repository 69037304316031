import React from "react"

import "../styles/journeys.scss"
import ChooseJourney from "../components/journeys"

const IndexPage = () => {
  return <ChooseJourney>Which best describes you?</ChooseJourney>
}

export default IndexPage
